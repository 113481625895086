import React from 'react';
import CustomButton from '../button/custom-button.component';
import CrystalForm from './crystal-form';

const CrystalNew = (props) => {
    return (
        <div>
            <br />
            <div className='middle backed-p'>
                {props.addMode ?
                    <div className='border'>
                        <CrystalForm
                            callback={props.action}
                            editMode={false} />
                    </div>
                    :
                    <div className='top'>
                        <CustomButton
                            buttonStyle="blue"
                            label="Add Crystal"
                            action={props.action}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default CrystalNew;
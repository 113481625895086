import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import { dropDownReducer } from './drop-down/drop-down.reducer'
import crystalReducer from './crystal/crystal.reducer';


export default combineReducers({
    user: userReducer,
    dropDown: dropDownReducer,
    crystal: crystalReducer
})
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCrystals, fetchSingleCrystal } from '../../reducers/crystal/crystal.actions';
import CrystalNew from '../../components/crystal/crystal-new';
import Crystals from '../../components/crystal/crystals';
import { useParams } from 'react-router-dom';

import './process.styles.scss';


const CrystalContainer = (props) => {
    const { crystalName } = useParams();
    const [addMode, setAddMode] = useState(false);
    const [authValue, setAuthValue] = useState(props.isAuthenticated);

    useEffect(() => {
        if (crystalName && !props.singleCrystalCalled) {
            props.fetchSingleCrystal(crystalName)
        } else if (!crystalName && !props.crystalCalled) {
            props.fetchCrystals();
        }
        setAuthValue(props.isAuthenticated)
    }, [props]);

    const showCrystalForm = () => {
        setAddMode(!addMode)
    }

    return (
        <div>
            <br />
            {crystalName ?
                null :
                <div>
                    <h1 className='centered backed-p'>Crystals</h1>
                    <br />
                </div>
            }
            {authValue ?
                <div>
                    <CrystalNew
                        addMode={addMode}
                        action={showCrystalForm} />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                : null}
            {props.singleCrystalCalled || props.crystalCalled && !crystalName ?
                <div className="centered backed3 bold">
                    {crystalName ?
                        <Crystals
                            crystals={props.singleCrystal} />
                        :
                        <Crystals
                            crystals={props.crystals} />
                    }
                </div>
                : null}
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCrystals: () => dispatch(fetchCrystals()),
        fetchSingleCrystal: (crystalName) => dispatch(fetchSingleCrystal(crystalName))
    }
}

const mapStateToProps = state => ({
    crystals: state.crystal.crystals,
    singleCrystal: state.crystal.singleCrystal,
    singleCrystalCalled: state.crystal.singleCrystalCalled,
    crystalCalled: state.crystal.called,
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps, mapDispatchToProps)(CrystalContainer);
import React, { useState, useEffect } from 'react';
import CustomButton from '../button/custom-button.component';
import CrystalForm from './crystal-form';
import { deleteCrystal } from '../../reducers/crystal/crystal.actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';





const SingleCrystal = props => {
    const { crystalName } = useParams();
    const [authValue, setAuthValue] = useState(props.isAuthenticated);
    const [editMode, updateEditMode] = useState(false);
    console.log(crystalName)

    useEffect(() => {
        setAuthValue(props.isAuthenticated)
    }, [props]);

    const setEditMode = () => {
        updateEditMode(!editMode)
    }

    const handleDelete = () => {
        if (window.confirm(
            "Are you sure you want to delete this crystal: " + props.crystal.crystalName + "?"
        )) {
            props.deleteCrystal(props.crystal.crystalId);
        }
    }

    return (
        <div>
            <div className='border centered'>
                {!editMode ?
                    <div>
                        <br/>
                        {props.crystal.imageUrl ?
                            <img className='crystal-img' src={props.crystal.imageUrl} />
                            :
                            <h2>No Image Supplied</h2>
                        }
                        <h1>{props.crystal.crystalName}</h1>
                        {props.crystal.crystalDescription ?
                            <h2>{props.crystal.crystalDescription}</h2>
                            :
                            null
                        }
                        {authValue ?
                            <div className="grid50">
                                <CustomButton action={setEditMode} buttonStyle="blue" label="Edit" />
                                <CustomButton action={handleDelete} buttonStyle="red" label="Delete" />
                            </div>
                            : null
                        }
                    </div>
                    :
                    <CrystalForm editMode={true} crystalInput={props.crystal} callback={setEditMode} />
                }
            </div>
            <br />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        deleteCrystal: (id) => dispatch(deleteCrystal(id))
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleCrystal);
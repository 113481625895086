import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import Home from './containers/main/home';
import Footer from './containers/main/footer';
import Header from './containers/main/header';
import AllCategories from './containers/categories/all-categories';
import CrystalContainer from './containers/crystals/crystals-container';
import Signin from './containers/registration/signin';
import Signout from './containers/registration/signout';
import { connect } from 'react-redux';
import { checkUser } from './reducers/user/user.actions';
import { toggleDropDown } from './reducers/drop-down/drop-down.reducer';

const App = (props) => {
    const [authValue, setAuthValue] = useState(props.isAuthenticated);

    useEffect(() => {
        let token = localStorage.getItem('token');
        let userId = localStorage.getItem('id');
        if (!props.isAuthenticated) {
            props.checkUser(userId, token);
        }
        setAuthValue(props.isAuthenticated)
    }, [props]);

    return (
        <div className="page">
            <Header />
            <div className='size-holder middle'>
                {authValue ?
                    <Routes >
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/tour' element={<AllCategories />} />
                        <Route exact path="/fb" component={() => { window.location = "https://www.facebook.com/CarolinesCrystals" }} />
                        <Route exact path="/ig" component={() => { window.location = "https://www.instagram.com/Carolines.crystals" }} />
                        <Route exact path='/signout' element={<Signout />} />
                        <Route exact path='/crystals' element={<CrystalContainer />} />
                        <Route path='/crystals/:crystalName' element={<CrystalContainer />} />
                    </Routes >
                    :
                    <Routes >
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/tour' element={<AllCategories />} />
                        <Route exact path="/fb" component={() => { window.location = "https://www.facebook.com/CarolinesCrystals" }} />
                        <Route exact path="/ig" component={() => { window.location = "https://www.instagram.com/Carolines.crystals" }} />
                        <Route exact path='/signin' element={<Signin />} />
                        <Route exact path='/crystals' element={<CrystalContainer />} />
                        <Route path='/crystals/:crystalName' element={<CrystalContainer />} />
                    </Routes >
                }
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        checkUser: (userId, token) => dispatch(checkUser(userId, token)),
        toggleDropDown: () => dispatch(toggleDropDown())
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
import CrystalActionTypes from './crystal.types';

const INITIAL_STATE = {
    crystals: [],
    singleCrystal: [],
    singleCrystalCalled: false,
    called: false
}

const crystalReducer = (state = INITIAL_STATE, action) => {
    console.log(action)
    switch (action.type) {
        case CrystalActionTypes.SET_CRYSTALS:
            return {
                ...state,
                crystals: action.payload.data,
                called: true
            };
        case CrystalActionTypes.ADD_CRYSTAL:
            return {
                ...state,
                crystals: [...state.crystals, action.payload]
            };
        case CrystalActionTypes.UPDATE_CRYSTAL:
            return {
                ...state,
                crystals: [
                    action.payload,
                    ...state.crystals
                        .filter((value) => {
                            return value.crystalName !== action.payload.crystalName
                        })]
                    .sort((first, second) => {
                        if (first.crystalName > second.crystalName) {
                            return 1
                        } else {
                            return -1
                        }
                    })
            };
        case CrystalActionTypes.DELETE_CRYSTAL:
            return {
                ...state,
                crystals: [...state.crystals
                    .filter((value) => {
                        return value.crystalName !== action.payload
                    })]
            };
        case CrystalActionTypes.SET_CRYSTAL_SINGLE:
            return {
                ...state,
                singleCrystal: action.payload.data,
                singleCrystalCalled: true
            };
        case CrystalActionTypes.SIGNOUT_USER:
            return {
                crystals: [],
                called: false
            };
        default:
            return state;
    }
}

export default crystalReducer;
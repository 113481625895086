import rootHttp from '../root-http';
import CrystalActionTypes from './crystal.types';
import helpers from '../../shared/helpers';


const http = new rootHttp();
const helper = new helpers();

export function fetchCrystals() {
    return dispatch => {
        http.fetchAll("crystal/All")
            .then((crystals) => {
                dispatch(setCrystals(crystals));
            });
    }
}
//Gets all crystals

export function fetchSingleCrystal(crystalName) {
    return dispatch => {
        http.fetchAll("crystal/" + crystalName)
            .then((crystals) => {
                dispatch(setCrystalSingle(crystals));
            });
    }
}
//Gets all crystals

export function addCrystal(crystal, callback) {
    crystal = prepCrystalValues(crystal);
    return dispatch => {
        http.updateItem("crystal", crystal, "")
            .then(addedCrystal => {
                dispatch(addCrystalToState(addedCrystal.data));
                callback();
            });
    }
}
//Posts new crystal to API

export function updateCrystal(crystal, callback) {
    crystal = prepCrystalValues(crystal);
    return dispatch => {
        http.updateItem("crystal", crystal, "")
            .then(() => {
                dispatch(updateCrystalInState(crystal));
                callback();
            });
    }
}
//Updates crystal in database

export function deleteCrystal(id) {
    return dispatch => {
        http.deleteItemById("crystal", id)
            .then(() => {
                dispatch(deleteCrystalFromState(id));
            });
    }
}
//Deletes selected crystal

export function addCrystalToState(crystal) {
    return {
        type: CrystalActionTypes.ADD_CRYSTAL,
        payload: crystal
    }
}
//Adds new crystal from post to state

export function setCrystals(crystals) {
    return {
        type: CrystalActionTypes.SET_CRYSTALS,
        payload: crystals
    }
}
//Sets all crystals in state

export function setCrystalSingle(crystals) {
    return {
        type: CrystalActionTypes.SET_CRYSTAL_SINGLE,
        payload: crystals
    }
}
//Sets all crystals in state

export function updateCrystalInState(crystal) {
    return {
        type: CrystalActionTypes.UPDATE_CRYSTAL,
        payload: crystal
    }
}
//Updates function for crystal

export function deleteCrystalFromState(id) {
    return {
        type: CrystalActionTypes.DELETE_CRYSTAL,
        payload: id
    }
}
//Deletes selected crystal

function prepCrystalValues(crystal) {
    crystal.crystalName = helper.capitalizeAll(crystal.crystalName);
    if (crystal.funcName) {
        crystal.funcName = helper.capitalize(crystal.funcName);
    }

    return crystal;
}
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addCrystal, updateCrystal } from '../../reducers/crystal/crystal.actions';
import CustomButton from '../button/custom-button.component';
import FormInput from '../form-input/form-input.component';


const CrystalForm = props => {
    const [crystalInfo, setCrystalInfo] = useState({
        crystalName: '',
        imageUrl: '',
        crystalDescription: ''
    });

    const { crystalName, imageUrl, crystalDescription } = crystalInfo;

    useEffect(() => {
        if (props.editMode) {
            Object.keys(props.crystalInput).forEach(key => {
                if (props.crystalInput[key] !== null) {
                    setCrystalInfo({ [key]: props.crystalInput[key] });
                }
            })
            setCrystalInfo(props.crystalInput);
        }
    }, [props])

    const handleSubmit = async event => {
        if (crystalInfo.imageUrl.includes("https://drive.google.com/file/d/")) {
            let urlParts = crystalInfo.imageUrl.split("/");
            crystalInfo.imageUrl = "https://drive.google.com/uc?export=view&id=" + urlParts[urlParts.length - 2]
        }
        event.preventDefault();
        if (props.editMode) {
            if (crystalInfo !== props.crystalInput) {
                props.updateCrystal(crystalInfo, props.callback);
            } else {
                props.callback();
            }
        } else {
            props.addCrystal(crystalInfo, props.callback);
        }
    };

    const handleChange = event => {
        const { name, value } = event.target;

        setCrystalInfo({ ...crystalInfo, [name]: value });
    };

    return (
        <div className='middle'>
            {!props.editMode ?
                <h3 className='centered'>
                    Fill out the form below to add a Crystal
                </h3>
                :
                <h3 className='centered'>
                    {props.crystalInput.crystalName}
                </h3>
            }
            <form onSubmit={handleSubmit}>
                {!props.editMode ?
                    <FormInput
                        label='Crystal Name'
                        type='crystalName'
                        name='crystalName'
                        value={crystalName}
                        onChange={handleChange}
                    />
                    :
                    null
                }
                <FormInput
                    label='Image Url'
                    type='imageUrl'
                    name='imageUrl'
                    value={imageUrl}
                    onChange={handleChange}
                    required
                />
                <FormInput
                    label='Crystal Description'
                    type='crystalDescription'
                    name='crystalDescription'
                    value={crystalDescription}
                    onChange={handleChange}
                    required
                />
                <div className="grid50">
                    {!props.editMode ?
                        <CustomButton
                            buttonStyle="blue"
                            type="submit"
                            label="Add"
                        />
                        :
                        <CustomButton
                            buttonStyle="blue"
                            type="submit"
                            label="Update"
                        />
                    }
                    <CustomButton
                        buttonStyle="red"
                        action={props.callback}
                        label="Cancel"
                    />
                </div>
            </form>
        </div>
    );
}


const mapDispatchToProps = dispatch => ({
    addCrystal: (crystal, callback) => {
        dispatch(addCrystal(crystal, callback))
    },
    updateCrystal: (crystal, callback) => {
        dispatch(updateCrystal(crystal, callback))
    }
});


export default connect(null, mapDispatchToProps)(CrystalForm);
import React from 'react';
import SingleCrystal from './single-crystal';

const Crystals = props => {

    return (
        <div>
            {props.crystals.length > 0 ?
                <div className='flex'>
                    {props.crystals.map(crystal => (
                        <div
                            key={crystal.crystalName}
                            className='sized30'>
                            <SingleCrystal
                                crystal={crystal} />
                        </div>
                    ))}
                </div>
                :
                <div className="border centered">
                    <h4 className="spaced">
                        Sorry, there are no crystals to display!
                    </h4>
                </div>
            }
        </div>
    )
}


export default Crystals;